exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-razorpay-js": () => import("./../../../src/pages/razorpay.js" /* webpackChunkName: "component---src-pages-razorpay-js" */),
  "component---src-pages-street-light-software-js": () => import("./../../../src/pages/street-light-software.js" /* webpackChunkName: "component---src-pages-street-light-software-js" */),
  "component---src-pages-talks-bitrise-js": () => import("./../../../src/pages/talks/bitrise.js" /* webpackChunkName: "component---src-pages-talks-bitrise-js" */),
  "component---src-pages-talks-fluttering-your-wings-with-react-native-js": () => import("./../../../src/pages/talks/fluttering-your-wings-with-react-native.js" /* webpackChunkName: "component---src-pages-talks-fluttering-your-wings-with-react-native-js" */),
  "component---src-pages-talks-git-lecture-js": () => import("./../../../src/pages/talks/git-lecture.js" /* webpackChunkName: "component---src-pages-talks-git-lecture-js" */),
  "component---src-pages-talks-how-and-why-of-automation-js": () => import("./../../../src/pages/talks/how-and-why-of-automation.js" /* webpackChunkName: "component---src-pages-talks-how-and-why-of-automation-js" */),
  "component---src-pages-talks-index-js": () => import("./../../../src/pages/talks/index.js" /* webpackChunkName: "component---src-pages-talks-index-js" */),
  "component---src-pages-unifize-js": () => import("./../../../src/pages/unifize.js" /* webpackChunkName: "component---src-pages-unifize-js" */)
}

